import React from 'react';
import { Button, Box } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import * as XLSX from 'xlsx';

const ExportButton = ({ transactions }) => {
  const exportToExcel = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    
    // Create a worksheet from the table data
    const ws = XLSX.utils.json_to_sheet(transactions.map(transaction => ({
      Id: transaction.transaction_id,
      Merchant: transaction.tenant.tenant_name,
      'Account No': transaction.account_number,
      Reference: transaction.originator_transaction_reference,
      Service: transaction.service?.service_name || 'Not available',
      Amount: transaction.transaction_amount,
      Commission: transaction.transaction_commission,
      Date: transaction.created_at,
      'Payment Status': transaction.receiver_narration
    })));
    
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Transactions');
    
    // Generate Excel file and trigger a download
    XLSX.writeFile(wb, 'transactions.xlsx');
  };
  
  return (
    <Box sx={{ mb:2 }}>
        <Button variant="contained" startIcon={<CloudDownloadIcon />} color="primary" onClick={exportToExcel}>
      Export to Excel
    </Button>

    </Box>
    
  );
};

export default ExportButton;
