import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List,ListItem, ListItemText,ListItemIcon, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

// NavItem.propTypes = {
//   item: PropTypes.object,
// };
NavItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string,
    icon: PropTypes.element,
    info: PropTypes.element,
    children: PropTypes.array,
  }),
};

function NavItem({ item }) {
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (children && children.length) {
      setOpen(!open);
    }
  };

  return (
    <>
      <StyledNavItem
        button
        component={path ? RouterLink : 'div'}
        to={path || ''}
        onClick={handleClick}
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {children && (open ? <ExpandLess /> : <ExpandMore />)}
      </StyledNavItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 4 }}>
            {children.map((childItem) => (
              <NavItem key={childItem.title} item={childItem} />
            ))}
          </List>
        </Collapse>
      )}
    </>
    // <StyledNavItem
    //   component={RouterLink}
    //   to={path}
    //   sx={{
    //     '&.active': {
    //       color: 'text.primary',
    //       bgcolor: 'action.selected',
    //       fontWeight: 'fontWeightBold',
    //     },
    //   }}
    // >
    //   <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

    //   <ListItemText disableTypography primary={title} />

    //   {info && info}
    // </StyledNavItem>
  );
}
