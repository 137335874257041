import React, { useState } from 'react';
import { Box, TableContainer, Paper, Table, TableHead, OutlinedInput,InputAdornment, TableRow, TableCell, TableBody, Checkbox, TablePagination,LinearProgress,CircularProgress,  Skeleton, Popover ,MenuItem} from '@mui/material'
import { styled, alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fShortenNumber } from '../utils/formatNumber';
import { fDateTime, fDate } from '../utils/formatTime';
import Label from './label';
import Iconify from './iconify/Iconify';
import Popper from './popper';
import ReverseTransaction from './reverseTrans';
import ReprocessTransaction from './reprocessTrans';
import UpdateTransactionAccount from './updateTransAccount';



const TransactionsListing = ({ transactions,metadata, page, setPage, limit, setlimit, onFilterName  }) => {
  console.log(metadata)
 
      const navigate = useNavigate()
      const dispatch = useDispatch();
      const {user, loading} = useSelector(state => state.usersslice);
      const token = user?.access_token
      const [open, setOpen] = useState(null);
      const [rowsPerPage, setRowsPerPage] = useState(10);
      const [transactionId, setTransactionId] = useState([])
      const [accNumber, setAccNumber] = useState([])

      const [reverse, setReverse] = useState(false)
      const [showReverse, setShowReverse] = useState(false)

      const [showReprocess, setReprocess] = useState(false)
      const [reprocessModal, setReprocessModal] = useState(false)

      const [showUpdateAccount, setShowUpdateAccount] = useState(false)
      const [showUpdateAccountModal, setShowUpdateAccountModal] = useState(false);

      const [filterName, setFilterName] = useState('');

      const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
      };

      const handleViewMore = () => {
        navigate(`${transactionId}`)
      }
      const handleReverse = () => {
        setOpen(false);
        setReverse(true)
      }
      const handleReprocess = () => {
        setOpen(false);
        setReprocessModal(true) 
      }

      const handleUpdateAccount = () => {
        setOpen(false);
        setShowUpdateAccountModal(true);
      }


      const handleChangePage = (event, newPage) => {
            setPage(newPage);
      };

      
      const handleChangeRowsPerPage = (event) => {
            setPage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
          };

      const handleOpenMenu = (event, id, status, acc) => {
      
        let showReprocess = false;
        let showReverse = false;
        let showUpdateAccount = false;

        switch (status) {
          case 100:
            showReprocess = true;
            break;   
          case 102:
            showReverse = true;
            showUpdateAccount = true;
            showReprocess = true;
            break;
          case 103:
            showReprocess = true;
            showReverse = true;
            
            break;
          default:
            break;      
        }

        setReprocess(showReprocess);
        setShowReverse(showReverse);
        setShowUpdateAccount(showUpdateAccount);
        
        setOpen(event.currentTarget);
        setTransactionId(id)
        setAccNumber(acc)
      };

      
      const handleCloseMenu = () => {
      setOpen(null);
      };

      const getLabelColor = (transactionStatus) => {
           
            switch (transactionStatus) {
              case 100:
                return 'info';
              case 101:
                return 'success';
              case 102:
                return 'secondary'  
              case 103:
                return 'error';
              case 104:
                return 'error';
              case 105:
                return 'warning';
              case 106:
                return 'primary';
              case 107:
                return 'error';
              case 108:
                return 'primary';  
              case 109:
                return 'error';
              case 110:
                return 'error';
              case 111:
                return 'info';
              default:
                return 'default';
            }
          };
          
          const getStatusLabel = (transactionStatus) => {
            
            switch (transactionStatus) {
              case 100:
                return 'Pending';
              case 101:
                return 'Success';
              case 102:
                return 'Issue resolution'
              case 103:
                return 'Failed';
              case 104:
                return 'Retries exceeded';
              case 105:
                return 'Insufficient balance';
              case 106:
                return 'Processing';
              case 107:
                return 'Reversed';
              case 108:
                return 'Refunded';
              case 109:
                return 'Cancelled';
              case 110:
                return 'Rejected';
              case 111:
                return 'On hold';
              default:
                return 'Unknown';
            }
          };
          
     


      return (
            <>
            <Popper size={"sm"} title={"Reverse transaction"} open={reverse} setOpen={setReverse}><ReverseTransaction setOpen={setReverse} id={transactionId} /></Popper>
            <Popper size={"sm"} title={"Reprocess transaction"} open={reprocessModal} setOpen={setReprocessModal}><ReprocessTransaction setOpen={setReprocessModal} id={transactionId} /></Popper>
            <Popper size={"sm"} title={"Update Account Number"} open={showUpdateAccountModal} setOpen={setShowUpdateAccountModal}><UpdateTransactionAccount setopen={setShowUpdateAccountModal} id={transactionId} acc={accNumber} /></Popper>

             <Box>
             
                  <Paper  >
                        <TableContainer sx={{ maxHeight: "80vh" }}>
                              <Table sx={{ minWidth: 750 }} size="small" stickyHeader aria-label="sticky table" >
                                    <TableHead>
                                          <TableRow>
                                                
                                                <TableCell align="center">Transaction Id</TableCell>
                                                { <TableCell>Merchant</TableCell>}
                                                { <TableCell>Account no</TableCell>}
                                                { <TableCell>Reference</TableCell>}
                                                {<TableCell align='left'>Service </TableCell>}
                                                {<TableCell align='left'>Amount</TableCell>}
                                                { <TableCell align='left'>Commission</TableCell>}
                                                { <TableCell align='center'>Date</TableCell>}
                                                { <TableCell align='left'>Status</TableCell>}
                                               
                                                <TableCell align="center"> Actions </TableCell>

                                          </TableRow>
                                    </TableHead>

                                    <TableBody>
                                          {loading ? <TableRow >
                                            <Box display="flex" alignItems="center" justifyContent="center" sx={{ p: 2, width: '100%' }}>
                                              <Box width={800}> 
                                                <LinearProgress color="secondary" />
                                              </Box>
                                            </Box>

                                            
                                            </TableRow> : (transactions?.map((transaction) => (
                                                <TableRow
                                                      key={transaction.invoice_id}
                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                      <TableCell align="center">{transaction?.transaction_id}</TableCell>
                                                      {<TableCell align='left'> <Label color={'secondary'} >{transaction?.tenant?.tenant_name}</Label> </TableCell>}
                                                      {<TableCell align='left'>{transaction?.account_number}</TableCell>}
                                                      {<TableCell align='left'>{transaction?.originator_transaction_reference}</TableCell>}
                                                      {transaction?.tenant_service?<TableCell align='left'><Label color={'info'} >{transaction?.tenant_service?.tenant_service_name}</Label>  </TableCell>: <TableCell align='left'>{"Not available"}</TableCell> }
                                                      {<TableCell align='left'>{fShortenNumber(transaction?.transaction_amount)}</TableCell>}
                                                      {<TableCell align='left'>{fShortenNumber(transaction?.transaction_commission)}</TableCell>}
                                                      {<TableCell align='center' ><Label>{fDateTime(transaction?.created_at)}</Label>    </TableCell>}
                                                      <TableCell align="left">
                                                      <Label color={getLabelColor(transaction?.transaction_status)}>
                                                      {getStatusLabel(transaction?.transaction_status)}
                                                      </Label>
                                                      </TableCell>
                                                      <TableCell align="right">
                                                      <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event,transaction?.transaction_id, transaction?.transaction_status, transaction?.account_number )}>
                                                      <Iconify icon={'eva:more-vertical-fill'} />
                                                      </IconButton>
                                                      </TableCell>
                                                   
                                                </TableRow>
                                          )))}
                                       
                                    </TableBody>

                              </Table>
                        </TableContainer>

                        <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={metadata?.total}
                        rowsPerPage={rowsPerPage}
                        page={metadata?.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                    
                  </Paper>
            </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleViewMore}>
          <Iconify icon={'eva:chevron-right-outline'} sx={{ mr: 2 }} />
          View more
        </MenuItem>
        
       {showReverse && (
         <MenuItem onClick={handleReverse}>
         <Iconify icon={'eva:undo-outline'} sx={{ mr: 2 }} />
         Reverse
       </MenuItem>
       )}

        {showReprocess && (
          <MenuItem onClick={handleReprocess}>
          <Iconify icon={'eva:repeat-outline'} sx={{ mr: 2 }} />
          Reprocess
        </MenuItem>

        )}

        {showUpdateAccount && (
            <MenuItem onClick={handleUpdateAccount}>
            <Iconify icon={'eva:edit-2-outline'} sx={{ mr: 2 }} />
            Update Acc
          </MenuItem>

          )}
        
      </Popover>
            
            </>
           
      )
}

export default TransactionsListing