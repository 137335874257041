import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { Box, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, TablePagination,  Skeleton, Popover ,MenuItem} from '@mui/material'

import IconButton from '@mui/material/IconButton';

import {
      GridRowModes,
      DataGrid,
      GridActionsCellItem,
} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateMerchant, deleteMerchant } from '../redux/slices/sliceActions';
import SnackBar from './snackBar';
import Iconify from './iconify/Iconify';

const MerchantListing = ({ tenants, page, setPage, }) => {

      const navigate = useNavigate()
      const { user, loading} = useSelector(state => state.usersslice);
      const token = user?.access_token;

      const dispatch = useDispatch();
      const [rows, setRows] = useState([]);
      const [rowModesModel, setRowModesModel] = useState({});
      const [success, setSuccess] = useState(false)
      const [open, setOpen] = useState(null);
      const [rowsPerPage, setRowsPerPage] = useState(5);
      const [vendorId, setVendorId] = useState([])
      
      const handleChangePage = (event, newPage) => {
            setPage(newPage);
      };

      
      const handleChangeRowsPerPage = (event) => {
            setPage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
          };

      const handleCloseMenu = () => {
      setOpen(null);
      }; 
      
      const handleViewMore = () => {
            navigate(`${vendorId}`)
      }


      const handleRowEditStart = (params, event) => {
            event.defaultMuiPrevented = true;
      };

      const handleRowEditStop = (params, event) => {
            event.defaultMuiPrevented = true;
      };

      const handleOpenMenu = (event, id) => {
            setOpen(event.currentTarget);
            setVendorId(id)
          };

      const handleEditClick = (id) => () => {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
      };

      const handleSaveClick = (id) => () => {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

      };

      const handleDeleteClick = (id) => () => {
            dispatch(deleteMerchant(id)) 
            setRows(rows.filter((row) => row.id !== id));
      };

      const handleCancelClick = (id) => () => {
            setRowModesModel({
                  ...rowModesModel,
                  [id]: { mode: GridRowModes.View, ignoreModifications: true },
            });

            const editedRow = rows.find((row) => row.id === id);
            if (editedRow.isNew) {
                  setRows(rows.filter((row) => row.id !== id));
            }
      };

      const processRowUpdate = (newRow) => {
            const tenant = {
                  tenant_id : newRow.tenant_id,
                  tenant_address: newRow.tenant_address,
                  tenant_domain: newRow.tenant_domain,
                  tenant_name: newRow.tenant_name,
                  tenant_phone_number: newRow.tenant_phone_number,
                  tenant_email_address: newRow.tenant_email_address }

            if (newRow.isNew) {
                  // dispatch(updateMerchant({ token, tenant })) 

            } else {
                  dispatch(updateMerchant({ tenant, token }))
                  
            }

            const updatedRow = { ...newRow, isNew: false };
            setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
            return updatedRow;
      };
 
      const columns = [
            {
                  align: 'left',
                  field: 'tenant_id',
                  headerName: 'Tenant Id',
                  type: "number",
                  headerAlign: 'left',
                  flex: .5,
                  editable: false,
            },
            {
                  field: 'tenant_name', headerName: 'Name', flex: 1.2,
                  editable: true
            },
            {
                  field: 'tenant_domain', headerName: 'Domain', flex: 1,
                  type: 'string', editable: true
            },
            {
                  field: 'tenant_email_address',
                  headerName: 'Email',
                  flex: 1,
                  editable: true,
                  

            },
            {
                  field: 'tenant_phone_number',
                  headerName: 'Phone number',
                  headerAlign: 'left',
                  type: "number",
                  flex: 1,
                  editable: true,

            },

          

            {
                  field: 'actions',
                  type: 'actions',
                  headerName: 'Actions',
                  flex: 1,
                  cellClassName: 'actions',
                  getActions: ({ id, phone }) => {
                        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                        if (isInEditMode) {
                              return [
                                    <GridActionsCellItem sx={{ m: 1 }}
                                          icon={<SaveIcon />}
                                          label="Save"
                                          onClick={handleSaveClick(id)}
                                    />,
                                    <GridActionsCellItem
                                          sx={{ m: 1 }}
                                          icon={<CancelIcon />}
                                          label="Cancel"
                                          className="textPrimary"
                                          onClick={handleCancelClick(id)}
                                          color="inherit"
                                    />,

                              ];
                        }


                        return [
                              <>
                              
                                <GridActionsCellItem
                                    sx={{ m: 1 }}
                                    icon={<EditIcon />}
                                    label="Edit"
                                    className="textPrimary"
                                    onClick={handleEditClick(id)}
                                    color="inherit"
                              />
                              <GridActionsCellItem
                                    sx={{ m: 1 }}
                                    icon={<DeleteIcon />}
                                    label="Delete"
                                    onClick={handleDeleteClick(id)}
                                    color="inherit"
                              />
                                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event,id)}>
                                  <Iconify icon={'eva:more-vertical-fill'} />
                                </IconButton>
                                   
                             

                              </>
                            
                        ];
                  },
            },
      ];

      useEffect(() => {
            setRows(tenants?.length ? tenants.map(x => ({
                   ...x, ...x.user, id: x.tenant_id,
                   phone: x.tenant_phone_number, 
                   house: x.house?.house_id, ...x.house, 
                
                  isNew: false, 
                   }))
                  : [])

            return () => {
                  setRows([])
            }
      }, [tenants])
      
    
      return (
//             <>

//             <Box>
//                  <Paper  >
//                        <TableContainer sx={{ maxHeight: "40vh" }}>
//                              <Table sx={{ minWidth: 750 }} size="small" stickyHeader aria-label="sticky table" >
//                                    <TableHead>
//                                          <TableRow>
                                               
//                                                <TableCell align="center">Tenant Id</TableCell>
//                                                { <TableCell>Name</TableCell>}
//                                                {<TableCell align='left'>Domain </TableCell>}
//                                                {<TableCell align='left'>Email</TableCell>}
//                                                { <TableCell align='left'>Phone</TableCell>}
//                                                <TableCell align="center"> Actions </TableCell>

//                                          </TableRow>
//                                    </TableHead>

//                                    <TableBody>
//                                          {loading ? <TableRow><Skeleton width={700} /></TableRow> : (tenants?.map((tenant) => (
//                                                <TableRow
//                                                      key={tenant?.tenant_id}
//                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
//                                                       <TableCell align="center">{tenant?.tenant_id}</TableCell>
//                                                      <TableCell align="center">{tenant?.tenant_name}</TableCell>
//                                                      {<TableCell align='left'>{tenant?.tenant_domain}</TableCell>}
//                                                       <TableCell align='left'>{tenant?.tenant_email_address} </TableCell>
//                                                      {<TableCell align='left'>{tenant?.tenant_phone_number}</TableCell>}
                                                     
//                                                      <TableCell align="right">
//                                                      <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event,tenant?.tenant_id)}>
//                                                      <Iconify icon={'eva:more-vertical-fill'} />
//                                                      </IconButton>
//                                                      </TableCell>
                                                  
//                                                </TableRow>
//                                          )))}
                                         
//                                    </TableBody>

//                              </Table>
//                        </TableContainer>

//                        <TablePagination
//                        rowsPerPageOptions={[5, 10, 25]}
//                        component="div"
//                        count={tenants?.length}
//                        rowsPerPage={rowsPerPage}
//                        page={page}
//                        onPageChange={handleChangePage}
//                        onRowsPerPageChange={handleChangeRowsPerPage}
//                  />

                   
//                  </Paper>
//            </Box>

//      <Popover
//        open={Boolean(open)}
//        anchorEl={open}
//        onClose={handleCloseMenu}
//        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
//        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
//        PaperProps={{
//          sx: {
//            p: 1,
//            width: 140,
//            '& .MuiMenuItem-root': {
//              px: 1,
//              typography: 'body2',
//              borderRadius: 0.75,
//            },
//          },
//        }}
//      >
//       <MenuItem onClick={handleEditClick}>
//           <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
//           Edit
//         </MenuItem>

//         <MenuItem sx={{ color: 'error.main' }}>
//           <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
//           Delete
//         </MenuItem>
//        <MenuItem onClick={handleViewMore}>
//         <Iconify icon={'eva:chevron-right-outline'} sx={{ mr: 2 }} />
//          View more
//        </MenuItem>
       
//      </Popover>
           
//            </>
            <>
            
            <Box
                  sx={{
                        height: 400,
                        maxWidth: '1280px',
                        minWidth: '100px',
                        '& .actions': {
                              color: 'text.secondary',
                        },
                        '& .textPrimary': {
                              color: 'text.primary',
                        }, 
                        overflowX: "auto",
                        overflowY: 'auto',
                        // '@media screen and (max-width: 600px)': {
                        //       height: 'auto', 
                        //       maxWidth: '100%', 
                        // },
                  }}
            >
                 
                  <DataGrid
                        rows={rows}
                        columns={columns}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        autoPageSize

                        componentsProps={{
                              toolbar: { setRows, setRowModesModel },
                        }}
                        experimentalFeatures={{ newEditingApi: true }}
                  />
                
            </Box>
     <Popover
       open={Boolean(open)}
       anchorEl={open}
       onClose={handleCloseMenu}
       anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
       transformOrigin={{ vertical: 'top', horizontal: 'right' }}
       PaperProps={{
         sx: {
           p: 1,
           width: 140,
           '& .MuiMenuItem-root': {
             px: 1,
             typography: 'body2',
             borderRadius: 0.75,
           },
         },
       }}
     >
      <MenuItem onClick={handleEditClick}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
       <MenuItem onClick={handleViewMore}>
        <Iconify icon={'eva:chevron-right-outline'} sx={{ mr: 2 }} />
         View more
       </MenuItem>
       
     </Popover>
           
            </>
           
      )
}

export default MerchantListing