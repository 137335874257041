import React, { useState } from 'react';
import { Grid, TextField, Button,  FormControl, FormGroup, InputLabel, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createMerchantFloatAccount } from '../redux/slices/sliceActions';
// import { createMerchantService } from '../redux/slices/sliceActions';
import SnackBar from './snackBar';



const CreateFloatAccount = ({  setOpen }) => {
  const dispatch = useDispatch()
  const {  user, loading, error} = useSelector(state => state.usersslice);
  const services = useSelector(state => state?.usersslice.services?.data);
  const merchantaccounts = useSelector(state => state?.usersslice.merchantaccounts?.data);
  const token = user?.access_token;

  const {user_id: id} = useParams();

  const [accountDetails, setAccount] = useState({
    tenant_float_account_name: "",
    currency: "",
    account_type: "",
    
  })

  const handleValueChange = (e) => {
    setAccount({ ...accountDetails, [e.target.name]: e.target.value })
  }
  const handleCreateservice = () => {
    const merchantFAccount = {tenant_float_account_name: accountDetails.tenant_float_account_name, currency: accountDetails.currency, account_type: accountDetails.account_type }
    dispatch(createMerchantFloatAccount({token,id, merchantFAccount}))
    setOpen(false);

  }

  return (
    <FormGroup >
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField type='text' name='tenant_float_account_name' value={accountDetails.tenant_float_account_name} fullWidth size='small' label='Float Account Name' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
      </Grid>
      <Grid item xs={6}>
      <FormControl fullWidth>
            <InputLabel id="currency_label">Currency</InputLabel>
            <Select
              required
              fullWidth
              labelId="currency_label"
              id="currency-select"
              value={accountDetails.currency}
              label="Float"
              name="currency"
              onChange={handleValueChange} size="small"
            >
                <MenuItem value={1}>Kes</MenuItem>

              {/* {services ? services.map(ser => (<MenuItem key={ser.service_id} value={ser.service_id}>{ser.service_name}</MenuItem>)) : <MenuItem value={0}>no services</MenuItem>} */}
            </Select>
          </FormControl>

      </Grid>
      <Grid item xs={6}>
      <FormControl fullWidth>
            <InputLabel id="account_type_label">Account Type</InputLabel>
            <Select
              required
              fullWidth
              labelId="account_type_label"
              id="account_type-select"
              value={accountDetails.account_type}
              label="Type"
              name="account_type"
              onChange={handleValueChange} size="small"
            >
                <MenuItem value={'FLOAT'}>Float</MenuItem>
                <MenuItem value={'COMMISSION'}>Commission</MenuItem>

              {/* {merchantaccounts ? merchantaccounts.map(maccounts => (<MenuItem key={maccounts.tenant_float_account_id} value={maccounts.tenant_float_account_id}>{maccounts.tenant_float_account_name}</MenuItem>)) : <MenuItem value={0}>no accounts</MenuItem>} */}
            </Select>
          </FormControl>

      </Grid>
      
      <Grid item xs={12}>
        <Button type='submit' onClick={handleCreateservice}>Submit</Button>
      </Grid>

    </Grid>
  </FormGroup>
  )
}

export default CreateFloatAccount