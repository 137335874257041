import { Navigate, Routes,Route } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import VendorDeatailsPage from './pages/VendorDetails';
import TransactionDetailsPage from './pages/TransactionDetails';
import FailedTransactions from './pages/FailedTransactions';
import IssueResolution from './pages/IssueResolution';

// ----------------------------------------------------------------------


export default function Router() {
 

  return (
    <Routes>
      <Route
        path="/dashboard/"
        element={<DashboardLayout />}
      >
        <Route index element={<Navigate to="/dashboard/app" />} />
        <Route path="app" element={<DashboardAppPage />} />
        <Route path="app/:t_id" element={<TransactionDetailsPage />} />

        <Route path="user" element={<UserPage />} />
        <Route path="user/:user_id" element={<VendorDeatailsPage/> } />
        <Route path="services" element={<ProductsPage />} />
        <Route path="failed-transactions" element={<FailedTransactions />} />
        <Route path="issue-transactions" element={<IssueResolution />} />
      </Route>

      <Route path="login" element={<LoginPage />} />
      <Route path="signup" element={<SignupPage />} />

      <Route element={<SimpleLayout />}>
        <Route index element={<Navigate to="/dashboard/app" />} />
        <Route path="404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>

      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}
