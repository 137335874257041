import React, { useState } from 'react';
import { Box, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, TablePagination,  Skeleton, Popover ,MenuItem} from '@mui/material'

import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { fShortenNumber } from '../utils/formatNumber';
import Label from './label';
import Iconify from './iconify/Iconify';

const ServiceListing = ({ serviceslist, page, setPage }) => {
 
     
      const { loading} = useSelector(state => state.usersslice);

      const [open, setOpen] = useState(null);
      const [rowsPerPage, setRowsPerPage] = useState(5);


      const handleChangePage = (event, newPage) => {
            setPage(newPage);
      };

      
      const handleChangeRowsPerPage = (event) => {
            setPage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
          };

      const handleOpenMenu = (event) => {
            setOpen(event.currentTarget);
          };
        
      const handleCloseMenu = () => {
      setOpen(null);
      };
     


      return (
            <>

             <Box>
                  <Paper  >
                        <TableContainer sx={{ maxHeight: "40vh" }}>
                              <Table sx={{ minWidth: 750 }} size="small" stickyHeader aria-label="sticky table" >
                                    <TableHead>
                                          <TableRow>
                                                
                                                <TableCell align="center">Service Id</TableCell>
                                                {<TableCell align='left'>Service name </TableCell>}
                                                {<TableCell align='left'>Service code</TableCell>}
                                                { <TableCell align='left'>Description</TableCell>}
                                                <TableCell align="center"> Actions </TableCell>

                                          </TableRow>
                                    </TableHead>

                                    <TableBody>
                                          {loading ? <TableRow><Skeleton width={700} /></TableRow> : (serviceslist?.map((service) => (
                                                <TableRow
                                                      key={service._id}
                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                      <TableCell align="center">{service?.service_id}</TableCell>
                                                      {<TableCell align='left'>{service?.service_name} </TableCell> }
                                                      {<TableCell align='left'>{service?.service_code}</TableCell>}
                                                      {<TableCell align='left'>{service?.service_description}</TableCell>}
                                                     
                                                      <TableCell align="right">
                                                      <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                                                      <Iconify icon={'eva:more-vertical-fill'} />
                                                      </IconButton>
                                                      </TableCell>
                                                   
                                                </TableRow>
                                          )))}
                                          
                                    </TableBody>

                              </Table>
                        </TableContainer>

                        <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={serviceslist?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                  />

                    
                  </Paper>
            </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
        
      </Popover>
            
            </>
           
      )
}

export default ServiceListing