import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, FormGroup, Select, MenuItem,InputLabel, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateTransactionAccount, fetchMerchantServices} from '../redux/slices/sliceActions';
import SnackBar from './snackBar';


const UpdateTransactionAccount = ({  setopen, id , acc}) => {


  const [show, setshow] = useState(false)
  const [mood, setMood] = useState("success")
  const [info, setinfo] = useState("")
  const {  user, merchantservices, loading, error} = useSelector(state => state.usersslice);
  const token = user?.access_token;
  const tid = user?.tenant;


  const handleDetails = (e) => {
    setTransactionDetails({ ...transactionDetail, [e.target.name]: e.target.value })
  }
  const dispatch = useDispatch()
  const [transactionDetail, setTransactionDetails] = useState({
    action: "UPDATE_ACCOUNT",
    transaction_id: id,
    account_number: acc,
    tenant_service_id: '',

    
  })

  const handleValueChange = (e) => {
    setTransactionDetails({ ...transactionDetail, [e.target.name]: e.target.value })
  }
  const handleUpdateTransactionAcc = () => {
    const details = {transaction_action: transactionDetail.action, transaction: transactionDetail.transaction_id,  account_number: transactionDetail.account_number, service: transactionDetail.tenant_service_id}
    dispatch(updateTransactionAccount({ token, details }))
    
    .then((responseData) => {
      if(responseData.payload.statusCode === 100){
        setshow(true);
        setinfo(responseData.payload.statusDescription);
      } else {
        setshow(true);
        setMood("error");
        setinfo(responseData.payload.statusDescription);
      }
    })
    .catch((err) => {
      console.log(err);
    })
    setTimeout(()=>{
      setopen(false);
    },3000);
    

  }

  useEffect(() => {
    dispatch(fetchMerchantServices({token, tid}));
  }, []);

  return (
    <>
    <FormGroup >
      <Grid container spacing={2}>
     
        <Grid item xs={12}>
          <TextField type='text' name='account_number' value={transactionDetail.account_number} fullWidth size='small' label='Account number' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
        </Grid>

        <Grid item xs={12}>
        <FormControl sx={{ width: "250px" }}>
            <Select onChange={handleDetails} name="tenant_service_id"
              value={transactionDetail.tenant_service_id} size="small" >

              {merchantservices?.length ? merchantservices.map(service =>
                (<MenuItem value={service.tenant_service_id} key={service.tenant_service_id}>{service.tenant_service_name}</MenuItem>))
                : <MenuItem value={0} >No services found</MenuItem>}
            </Select>
          </FormControl> 
        
        </Grid>
        
        <Grid item xs={12}>
          <Button type='submit' onClick={handleUpdateTransactionAcc}>Update</Button>
        </Grid>

      </Grid>
    </FormGroup>
    <SnackBar value={show} text={info} mood={mood} />
    </>
  )
}

export default UpdateTransactionAccount;