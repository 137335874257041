// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'vendors',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'services',
    path: '/dashboard/services',
    icon: icon('ic_cart'),
  },
  {
    title: 'transactions',
    icon: icon('ic_transaction'),
    children: [
      {
        title: 'failed transactions',
        path: '/dashboard/failed-transactions',
        icon: icon('ic_failed_transaction'),
      },
      {
        title: 'Issue Resolution',
        path: '/dashboard/issue-transactions',
        icon: icon('ic_issue_transaction'),
      },
    ],
  },
  
 
  {
    title: 'login',
    path: '/login',
    icon: icon('ic_lock'),
  },
 
];

export default navConfig;
