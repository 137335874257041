import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useDispatch,useSelector } from 'react-redux';

// @mui
import { Container, Typography, Stack, Button } from '@mui/material';
// components
import {  ProductList } from '../sections/@dashboard/products';
import Iconify from '../components/iconify/Iconify';
import Popper from '../components/popper';
import CreateService from '../components/createService';
import ServiceListing from '../components/serviceListing';
// mock


import { fetchServices } from '../redux/slices/sliceActions';



// ----------------------------------------------------------------------

export default function ProductsPage() {
  const dispatch = useDispatch();
  const { services, user, loading, error} = useSelector(state => state.usersslice);
  const token = user?.access_token;

  const servicesList = services?.data;

  const [showAddService, setshowAddService] = useState(false)
  const [open, setOpen] = useState(false)

  const [limit, setlimit] = useState(6)
  const [page, setpage] = useState(0)

  const handleAddService = () => {
    setshowAddService(true);
  }

  useEffect(() => {
    dispatch(fetchServices(token))
  }, [token]);
  


  return (
    <>
      <Helmet>
        <title> Dashboard: Services | Kwetu Kash </title>
      </Helmet>

      <Container>
      <Popper size={"sm"} title={"Create Service"} open={showAddService} setOpen={setshowAddService}><CreateService setOpen={setshowAddService} /></Popper>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Services
          </Typography>
          <Button onClick={handleAddService} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New service
          </Button>
        </Stack>

        

        <ServiceListing serviceslist={servicesList}  page = {page} setPage={setpage} />
      </Container>
    </>
  );
}
