import React, { useState } from 'react';
import { Grid, TextField, Button, FormGroup } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createServices } from '../redux/slices/sliceActions';
import SnackBar from './snackBar';


const CreateService = ({  setOpen }) => {
  const dispatch = useDispatch()
  const {  user, loading, error} = useSelector(state => state.usersslice);
  const token = user?.access_token;
  const [serviceDetails, setservice] = useState({
    service_name: "",
    service_code: "",
    description: "",
    
  })

  const handleValueChange = (e) => {
    setservice({ ...serviceDetails, [e.target.name]: e.target.value })
  }
  const handleCreateservice = () => {
    const services = {service_name: serviceDetails.service_name, service_code: serviceDetails.service_code, service_description: serviceDetails.description }
    dispatch(createServices({token, services}))
    setOpen(false);

  }

  return (
    <FormGroup >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField type='text' name='service_name' value={serviceDetails.service_name} fullWidth size='small' label='Service Name' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
        </Grid>
        <Grid item xs={6}>
          <TextField type='text' name='service_code' value={serviceDetails.service_code} fullWidth size='small' label='Service Code' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
        </Grid>
        <Grid item xs={6}>
          <TextField type='text' name='description' value={serviceDetails.description} fullWidth size='small' label='Service description' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
        </Grid>
        
        <Grid item xs={12}>
          <Button type='submit' onClick={handleCreateservice}>Submit</Button>
        </Grid>

      </Grid>
    </FormGroup>
  )
}

export default CreateService