import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

    export const baseUrl = process.env.REACT_APP_ENDPOINT;
    export const liveUrl = process.env.REACT_APP_LIVE_ENDPOINT;

    export const loginUser = createAsyncThunk("user/loginUser", async (loginDetails) => {
        return axios.post(`${baseUrl}/v1/auth/login`,  {...loginDetails}, { withCredentials: true }).then(res=> res.data)
        })

   
        
    export const adminView = createAsyncThunk('user/adminView', async ({token,page, applyFilters}) => {
        const ref = applyFilters.reference || "";
        const account = applyFilters.filterAccountName || "";
        const id = applyFilters.id || '';
        const amount = applyFilters.amount || '';
        const pstatus = applyFilters.transStatus || '';
        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.get(
        `${baseUrl}/v1/dashboard?limit=10&page=${page}&payment_reference=${ref}&account_number=${account}&transaction_id=${id}&amount=${amount}`,
        { ...config, withCredentials: true }
        );
        return response.data;
        }); 



    export const fetchMerchants = createAsyncThunk('merchants/fetch', async ({page, limit,token}) => {
        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.get(
        `${baseUrl}/v1/tenants?page=${page+1}&limit=${limit}`,
        { ...config, withCredentials: true }
        );
        return response.data;
        }); 

    
        
    export const createMerchant = createAsyncThunk("merchants/create", async(merchant, thunkApi) => {
        return axios.post(`${baseUrl}/v1/tenants`, {...merchant}, { withCredentials: true }).then(res=>{
                thunkApi.dispatch(fetchMerchants({page:0, limit:12 }))
                return res.data
        }).catch(err=>{
                thunkApi.rejectWithValue(err)
        })
    })    

    export const deleteMerchant = createAsyncThunk("merchants/delete", async(id) => {
        return axios.delete(`${baseUrl}/v1/tenants/${id}`, { withCredentials: true }).then(res=> res.data)
        })  

     

    export const updateMerchant = createAsyncThunk('merchants/update', async ({tenant, token}) => {
       
        
        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.patch(
        `${baseUrl}/v1/tenants/${tenant.tenant_id}`,
        {...tenant},
        { ...config, withCredentials: true }
        );
        return response.data;
        }); 

    

    export const logoutUser = createAsyncThunk('user/logout', async (token) => {
        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.get(
        `${baseUrl}/v1/auth/logout`,
        { ...config, withCredentials: true }
        );
        return response.data;
        }); 
        
        
    export const fetchServices = createAsyncThunk('services/fetch', async (token) => {
        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.get(
        `${baseUrl}/v1/services`,
        
        { ...config, withCredentials: true }
        );
        return response.data;
        }); 
        
    export const fetchMerchantServices = createAsyncThunk('merchantservices/fetch', async ({token, tid}) => {
        
        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.get(
        `${baseUrl}/v1/tenants/${tid}/services`,
        
        { ...config, withCredentials: true }
        );
        return response.data;
        });    
        
    export const createServices = createAsyncThunk('services/create', async ({token,services}) => {
        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.post(
        `${baseUrl}/v1/services`,
        {...services},
        { ...config, withCredentials: true }
        );
        return response.data;
        });  

    export const fetchMerchant = createAsyncThunk('merchant/fetch', async ({token, id}) => {
        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.get(
        `${baseUrl}/v1/tenants/${id}`,
        
        { ...config, withCredentials: true }
        );
        return response.data;
        }); 

    export const createMerchantService = createAsyncThunk('merchantservices/create', async ({token,id, merchantServices}, thunkApi) => {
        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.post(
        `${baseUrl}/v1/tenants/${id}/services`,
        {...merchantServices},
        { ...config, withCredentials: true }
        );
        
        if (response.status === 201) {
            thunkApi.dispatch(fetchMerchant({token,id}));
          }
        return response.data;
        }); 

    export const fetchMerchantAccounts = createAsyncThunk('merchantaccounts/fetch', async ({token, id}) => {
        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.get(
        `${baseUrl}/v1/tenants/${id}/accounts`,
        
        { ...config, withCredentials: true }
        );
        return response.data;
        }); 

    export const createMerchantFloatAccount = createAsyncThunk('floataccounts/create', async ({token,id, merchantFAccount}, thunkApi) => {

        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.post(
        `${baseUrl}/v1/tenants/${id}/accounts`,
        {...merchantFAccount},
        { ...config, withCredentials: true }
        );
        if (response.status === 201) {
            thunkApi.dispatch(fetchMerchant({token,id}));
            }
        return response.data;
        }); 


    export const fetchTransactions = createAsyncThunk('transactions/fetch', async ({token, page}) => {
       
        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.get(
        `${baseUrl}/v1/transactions?limit=10&page=${page}`,
        
        { ...config, withCredentials: true }
        );
        return response.data;
        }); 

        export const fetchTransaction = createAsyncThunk('transaction/fetch', async ({token, id}) => {
            const config = {
                headers: {
                Authorization: `Bearer ${token}`, 
                },
            };
            const response = await axios.get(
            `${baseUrl}/v1/transactions/${id}`,
            
            { ...config, withCredentials: true }
            );
            return response.data;
            }); 

    export const reverseTransaction = createAsyncThunk('transaction/reverse', async ({token, details}, thunkApi) => {

        const config = {
            headers: {
            Authorization: `Bearer ${token}`, 
            },
        };
        const response = await axios.post(
        `${baseUrl}/v1/actions`,
        {...details},
        { ...config, withCredentials: true }
        );

        if (response.status === 201) {
            thunkApi.dispatch(adminView({token, page:1}));
          }
        
          const responseData = response.data
          return responseData;
        }); 

        export const reprocessTransaction = createAsyncThunk('transaction/reprocess', async ({token, details}, thunkApi) => {
           
                
            const config = {
                headers: {
                Authorization: `Bearer ${token}`, 
                },
            };
            const response = await axios.post(
            `${baseUrl}/v1/actions`,
            {...details},
            { ...config, withCredentials: true }
            );
            
            if (response.status === 201) {
                thunkApi.dispatch(issueRTransactions({token,transStatus:102, page:1}));
              }

            // if (response.status === 201) {
            //     thunkApi.dispatch(adminView({token, page:1}));
            //   }
            
            const responseData = response.data
            return responseData;
            });

        export const updateTransactionAccount = createAsyncThunk('account/update', async ({token, details}, thunkApi) => {
           
            const config = {
                headers: {
                Authorization: `Bearer ${token}`, 
                },
            };
            const response = await axios.post(
            `${baseUrl}/v1/actions`,
            {...details},
            { ...config, withCredentials: true }
            );
           

            if (response.status === 201) {
                thunkApi.dispatch(issueRTransactions({token,transStatus:102, page:1}));
              }

            const responseData = response.data
            return responseData;
            });  

            
            
            

        export const fetchBalances = createAsyncThunk("balances/fetch", async() => {
            return axios.get(`${baseUrl}/v1/sp/balances`, { withCredentials: true }).then(res=> res.data)
            })     

        export const failedTransactions = createAsyncThunk('trnasactions/failed', async ({token, transStatus, page}) => {
            
            const config = {
                headers: {
                Authorization: `Bearer ${token}`, 
                },
            };
            const response = await axios.get(
            `${baseUrl}/v1/dashboard?limit=10&transaction_status=${transStatus}&page=${page}`,
            { ...config, withCredentials: true }
            );
            return response.data;
            });
            
            export const issueRTransactions = createAsyncThunk('transactions/issue', async ({token, transStatus, page}) => {
            
                const config = {
                    headers: {
                    Authorization: `Bearer ${token}`, 
                    },
                };
                const response = await axios.get(
                `${baseUrl}/v1/dashboard?limit=10&transaction_status=${transStatus}&page=${page}`,
                { ...config, withCredentials: true }
                );
                return response.data;
                });    


            
    
