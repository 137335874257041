import React, { useState,  useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme, experimentalStyled as styled } from '@mui/material/styles';
import {  Container, Typography} from '@mui/material';
import Card from '@mui/material/Card';
import Iconify from '../components/iconify/Iconify';

import { adminView, fetchBalances, issueRTransactions } from '../redux/slices/sliceActions';
// components

import TransactionsListing from '../components/transactionsListing';
// sections


// ----------------------------------------------------------------------


export default function IssueResolution({color = 'primary', setPage}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { issuetransactions, user, loading, error} = useSelector(state => state.usersslice);
  const trans = issuetransactions?.transactions
  const metadata = issuetransactions?.metadata
  
  const token = user?.access_token
  const transStatus = 102;
  
  const [limit, setlimit] = useState(10)
  const [page, setpage] = useState(1)

  const [filterName, setFilterName] = useState('');


  useEffect(() => {
    
    dispatch(issueRTransactions({token, transStatus, page}));
    
    
  }, [dispatch, user, page, filterName ]);

  useEffect(() => {
    if(loading === false  && !user?.access_token || error === "403"){
      navigate('/login')
    }
    
  }, [loading,user, navigate]);
  
  return (
    <>
      <Helmet>
        <title> Transactions | Kwetu Kash </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>
          Issue Resolution Transactions
        </Typography>

        <Card sx={{ p:2, mt:3 }}>
            
           <TransactionsListing transactions={trans} metadata={metadata} limit={limit} setlimit={setlimit} page = {page} setPage={setpage} />
        </Card>
       
      </Container>
    </>
  );
}
