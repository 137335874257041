import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  CardHeader,
  Container,
  Typography,
  Divider,
  Stack,
  Grid,
 
} from '@mui/material';
import {fetchTransaction} from '../redux/slices/sliceActions';
// hooks

// components

import Iconify from '../components/iconify';
import SnackBar from '../components/snackBar';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { fDate } from '../utils/formatTime';
import { fShortenNumber } from '../utils/formatNumber';




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function TransactionDetailsPage({color = 'primary'}) {
  const dispatch = useDispatch()
  const [show, setshow] = useState(false)
  const {t_id: id} = useParams();
 
  const { user, error: errorInfo, transaction} = useSelector(state => state.usersslice);
  const [info, setinfo] = useState("")
  const token = user?.access_token

 
  useEffect(() => {
    dispatch(fetchTransaction({token, id}))
  }, []);


  return (
    <>
      <Helmet>
        <title> VendorDetails | Kwetu Kash </title>
      </Helmet>

      <StyledRoot>
        

        <Container maxWidth="md">
          <Card sx={{mb:2}} >
            <CardHeader title={transaction?.transaction_narration} subheader={transaction?.originator_transaction_reference} />

            <Scrollbar>
                <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Box sx={{ minWidth: 240, flexGrow: 1 }}>
                       <Typography variant="body2" sx={{pr: 3, color: 'text.secondary' }} noWrap>
                        Account.no: {transaction?.account_number}
                        </Typography>

                        <Typography variant="body2" sx={{pr: 3, color: 'text.secondary' }} noWrap>
                        Amount: {fShortenNumber(transaction?.transaction_amount)}
                        </Typography>

                        <Typography  variant="body2" underline="hover" sx={{pr: 3, color: 'text.secondary' }} noWrap>
                         Transaction Ref: {transaction?.transaction_reference}
                        </Typography>

                        
                    </Box>
                    <Box sx={{ minWidth: 240, flexGrow: 1 }}>
                       

                        <Typography variant="body2" sx={{pr: 3, color: 'text.secondary' }} noWrap>
                         Originator Ref: {transaction?.originator_transaction_reference}
                        </Typography>

                        <Typography  variant="body2" underline="hover" sx={{pr: 3, color: 'text.secondary' }} noWrap>
                         Commission: {fShortenNumber(transaction?.transaction_commission)}
                        </Typography>

                        <Typography variant="body2" sx={{pr: 3, color: 'text.secondary' }} noWrap>
                         Receiver Ref: {transaction?.receiver_transaction_reference}
                        </Typography>
                    </Box>

                    
                </Stack>
                </Stack>
            </Scrollbar>

            <Divider />
            </Card>

            

            <Grid container spacing={3} >

                    <Grid  item xs={12} sm={6} md={3}>
                     <Card 
                     sx={{
                     boxShadow: 0,
                     color: (theme) => theme.palette[color].darker,
                     bgcolor: (theme) => theme.palette[color].lighter,
                     
                     }} >
                         <Stack spacing={2} sx={{ p: 2 }}>
                             <Typography variant="subtitle2" noWrap style={{ color: '#004d40' }}>
                             {"Tenant details"}
                             </Typography>
                         
                         <Stack  justifyContent="space-between">
                            <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                             Name: {transaction?.tenant?.tenant_name}
                             </Typography>
                             <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                               Phone: {transaction?.tenant?.tenant_phone_number}
                             </Typography>
                             <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                               Email: {transaction?.tenant?.tenant_email_address}
                             
                             </Typography>
                             <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                              Domain: {transaction?.tenant?.tenant_domain}
                             </Typography>
                         </Stack>
                         </Stack>
                     </Card>
                     </Grid>

                     <Grid  item xs={12} sm={6} md={3}>
                     <Card 
                     sx={{
                     boxShadow: 0,
                     color: (theme) => theme.palette[color].darker,
                     bgcolor: (theme) => theme.palette[color].lighter,
                     
                     }} >
                         <Stack spacing={2} sx={{ p: 2 }}>
                             <Typography variant="subtitle2" noWrap style={{ color: '#004d40' }}>
                             {"Channel"}
                             </Typography>
                         
                           <Stack  justifyContent="space-between">
                             <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                             Name: {transaction?.channel?.channel_code ? transaction?.channel?.channel_code : "N/A" }
                             </Typography>
                             <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                               Desc: {transaction?.channel?.channel_description ? transaction?.channel?.channel_description : "N/A"}
                             </Typography>
                             <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                               Status: {transaction?.channel?.is_active === 1? "Active" : "Inactive"}
                             
                             </Typography>
                             <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                              Created: {fDate(transaction?.channel?.created_at)}
                             </Typography>
                         </Stack>
                         </Stack>
                     </Card>
                     </Grid>


                     <Grid  item xs={12} sm={6} md={3}>
                     <Card 
                     sx={{
                     boxShadow: 0,
                     color: (theme) => theme.palette[color].darker,
                     bgcolor: (theme) => theme.palette[color].lighter,
                     
                     }} >
                         <Stack spacing={2} sx={{ p: 2 }}>
                             <Typography variant="subtitle2" noWrap style={{ color: '#004d40' }}>
                             {'Tenant paybill'}
                             </Typography>
                         
                         <Stack  justifyContent="space-between">
                            <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                              Paybill: {transaction?.paybill?.paybill_number ? transaction?.paybill?.paybill_number : "N/A"}
                             </Typography> 
                              <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                              Shared: {transaction?.paybill?.paybill_settings?.shared === true? "True" : "False"}
                             </Typography>
                             <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                               Status: {transaction?.paybill?.is_active === 1? "Active" : "Inactive"}
                             </Typography>
                             <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                               Created: {fDate(transaction?.paybill?.created_at) ? fDate(transaction?.paybill?.created_at) : "N/A"}
                             
                             </Typography>
                            
                         </Stack>
                         </Stack>
                     </Card>
                     </Grid>

                     <Grid  item xs={12} sm={6} md={3}>
                     <Card 
                     sx={{
                     boxShadow: 0,
                     color: (theme) => theme.palette[color].darker,
                     bgcolor: (theme) => theme.palette[color].lighter,
                     
                     }} >
                         <Stack spacing={2} sx={{ p: 2 }}>
                             <Typography variant="subtitle2" noWrap style={{ color: '#004d40' }}>
                             {'Tenant service'}
                             </Typography>
                         
                         <Stack  justifyContent="space-between">
                            <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                             Name: {transaction?.tenant_service?.tenant_service_name ? transaction?.tenant_service?.tenant_service_name : "N/A" }
                             </Typography>
                             <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                               Code: {transaction?.tenant_service?.tenant_service_code ? transaction?.tenant_service?.tenant_service_code : "N/A" }
                             </Typography>
                             <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                               Status: {transaction?.tenant_service?.is_active === 1? "Active" : "Inactive"}
                             
                             </Typography>
                             <Typography variant="body2" noWrap style={{ color: '#00796b' }}>
                              Created: {fDate(transaction?.tenant_service?.created_at) ? fDate(transaction?.tenant_service?.created_at) : "N/A" }
                             </Typography>
                         </Stack>
                         </Stack>
                     </Card>
                     </Grid>

              

               
                </Grid>

                <Box className="greetings" sx={{ my: 2, display: "flex", alignItems: {xs: "flex-start",lg:"center"}, justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", flexDirection: "column"}}>
                            <Typography fontFamily="'Karla', sans serif" fontWeight="600" fontSize="1.8rem" color="#00695c"  variant="h4" whiteSpace="nowrap" overflow="hidden" textOverflow={"ellipsis"}>
                                Float Account Transactions
                            </Typography>
                            
                    </Box>
                </Box>


                {transaction?.float_account_transactions?.map((trans, index)=>(
                     <Card key={index} sx={{mb:2, mt:2}} >
                     <CardHeader title={trans?.transaction_type}  />
         
                     <Scrollbar>
                         <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
                         <Stack direction="row" alignItems="center" spacing={2}>
                             <Box sx={{ minWidth: 240, flexGrow: 1 }}>
                                <Typography variant="body2" sx={{pr: 3, color: 'text.secondary' }} noWrap>
                                 Amount:  {fShortenNumber(trans?.transaction_amount)}
                                 </Typography>
         
                                 <Typography variant="body2" sx={{pr: 3, color: 'text.secondary' }} noWrap>
                                 Balance before: {fShortenNumber(trans?.balance_before)}
                                 </Typography>
         
                                 <Typography  variant="body2" underline="hover" sx={{pr: 3, color: 'text.secondary' }} noWrap>
                                  Balance after: {fShortenNumber(trans?.balance_after)}
                                 </Typography>
         
                                 
                             </Box>
                             <Box sx={{ minWidth: 240, flexGrow: 1 }}>
                                
         
                                 <Typography variant="body2" sx={{pr: 3, color: 'text.secondary' }} noWrap>
                                  Narration: {trans?.transaction_narration}
                                 </Typography>
         
                                 <Typography  variant="body2" underline="hover" sx={{pr: 3, color: 'text.secondary' }} noWrap>
                                  Ref: {trans?.transaction_reference}
                                 </Typography>
         
                                 <Typography variant="body2" sx={{pr: 3, color: 'text.secondary' }} noWrap>
                                  Source Id: {trans?.source_transaction_id}
                                 </Typography>
                             </Box>
                         </Stack>
                         </Stack>
                     </Scrollbar>
         
                     <Divider />
                     </Card>
                ))}

               

        </Container>
        <SnackBar value={show} text={info} mood={(errorInfo>=200 && errorInfo<300)? "success":'error'} />
      </StyledRoot>
    </>
  );
}
