import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';

// @mui
import { styled } from '@mui/material/styles';
import {
  Link,
  Container,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  Divider,
  Checkbox,
  Stack,
  Button,
} from '@mui/material';
import { loginUser } from '../redux/slices/sliceActions';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
import SnackBar from '../components/snackBar';
// sections


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [show, setshow] = useState(false)
  const [error, seterror] = useState(false)
  const mdUp = useResponsive('up', 'md');
  const [showPassword, setShowPassword] = useState(false);
  const { user, error: errorInfo, loading, message} = useSelector(state => state.usersslice);
  const [info, setinfo] = useState("")

  const [logins, setlogins] = useState({
    email: "",
    password: ""
  })

  const handleLogins = (e) => {
    setlogins({ ...logins, [e.target.name]: e.target.value })
  }

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(loginUser({ user_email: logins.email.trim(), password: logins.password.trim() })) 
  }


  useEffect(() => {
    if (Number(errorInfo) === 401 || errorInfo.trim()){
      setinfo(Number(errorInfo) === 401? "Wrong credentials!": "Session expired, Please login!")
      setshow(true)
  }else if(!loading && message === "login successful") { 
   seterror(false)
   setshow(true)
   navigate('/dashboard')
  
  
 }
 
    
  }, [user,error, message])

  return (
    <>
      <Helmet>
        <title> Login | Kwetu Kash </title>
      </Helmet>

      <StyledRoot>
        

        <Container maxWidth="sm">
          <StyledContent>
            <Logo
            sx={{
              position: 'absolute',
              top: '20%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              justifyContent: 'center',
              
            }}
          />
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>
            <Stack spacing={3}>
              <TextField name="email" label="Email address" onChange={handleLogins} value={logins.email} />

              <TextField
                name="password"
                label="Password"
                value={logins.password} 
                onChange={handleLogins}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <Checkbox name="remember" label="Remember me" />
              <Link variant="subtitle2" underline="hover">
                Forgot password?
              </Link>
            </Stack>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin}>
              Login
            </LoadingButton>
          </StyledContent>
        </Container>
        <SnackBar value={show} text={info} mood={(errorInfo>=200 && errorInfo<300)? "success":'error'} />
      </StyledRoot>
    </>
  );
}
