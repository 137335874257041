import React, { useState } from 'react';
import { Grid, TextField, Button, FormGroup } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { reverseTransaction } from '../redux/slices/sliceActions';
import SnackBar from './snackBar';

const ReverseTransaction = ({  setOpen, id }) => {
  const [show, setshow] = useState(false)
  const [mood, setMood] = useState("success")
  const [info, setinfo] = useState("")

  const {  user, loading, error} = useSelector(state => state.usersslice);
  const token = user?.access_token;
  const dispatch = useDispatch()
  const [transactionDetail, setTransactionDetails] = useState({
    action: "REVERSE",
    transaction_id: id,
    narration: "",
    
  })

  const handleValueChange = (e) => {
    setTransactionDetails({ ...transactionDetail, [e.target.name]: e.target.value })
  }
  const handleReverseTransaction = () => {
    const details = {transaction_action: transactionDetail.action, transaction: transactionDetail.transaction_id,  narration: transactionDetail.narration}
    dispatch(reverseTransaction({ token, details }))
    .then((responseData)=> {
      if(responseData.payload.statusCode === 100){
        setshow(true)
        setinfo(responseData.payload.statusDescription)
      }else {
        setshow(true)
        setMood("error")
        setinfo(responseData.payload.statusDescription)
      }
    })
    .catch((err) => {
      console.log(err);
    })
    setTimeout(()=>{
      setOpen(false);
    },3000);

  }

  return (
    <>
    <FormGroup >
      <Grid container spacing={2}>
     
        <Grid item xs={12}>
          <TextField type='text' name='narration' value={transactionDetail.narration} fullWidth size='small' label='Reason for reversing' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
        </Grid>
        
        <Grid item xs={12}>
          <Button type='submit' onClick={handleReverseTransaction}>Submit</Button>
        </Grid>

      </Grid>
    </FormGroup>
    <SnackBar value={show} text={info} mood={mood} />
    </>
  )
}

export default ReverseTransaction;