import React, { useState } from 'react';
import { Grid, TextField, Button, Chip, Avatar, Typography, FormControl, FormGroup, InputLabel, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { createMerchant } from '../redux/slices/sliceActions';
import SnackBar from './snackBar';


export const flag = "https://img.icons8.com/color/48/000000/kenya-circular.png";

const CreateMerchant = ({  setOpen }) => {
  const dispatch = useDispatch()
  const [tenantDetails, settenant] = useState({
    tenant_phone_number: 7,
    tenant_name: "",
    tenant_email: "",
    domain: "",
    pin: "",
    address: 0,
  })

  const handleValueChange = (e) => {
    settenant({ ...tenantDetails, [e.target.name]: e.target.value })
  }
  const handleCreatetenant = () => {
    dispatch(createMerchant({
      tenant_phone_number: `254${tenantDetails.tenant_phone_number.split("").slice(-9).join("")}`, tenant_name: tenantDetails.tenant_name, tenant_email_address: tenantDetails.tenant_email, tenant_domain: tenantDetails.domain, tenant_business_pin: tenantDetails.pin, tenant_address: tenantDetails.address
    }))
    setOpen(false);

  }

  return (
    <FormGroup >
      <Grid container spacing={2}>
      {/* <SnackBar value={tenancy} text="There's no a vacant room!" mood="warning" /> */}
        <Grid item xs={6}>
          <TextField type='text' name='tenant_name' value={tenantDetails.tenant_name} fullWidth size='small' label='Full Names' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
        </Grid>
        <Grid item xs={6}>
          <TextField type='text' name='tenant_email' value={tenantDetails.tenant_email} fullWidth size='small' label='Email' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
        </Grid>
        <Grid item xs={6}>
          <TextField type='text' name='domain' value={tenantDetails.domain} fullWidth size='small' label='Domain' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
          <Chip
            avatar={<Avatar alt="" src={flag} />}
            label="+254" />
          <TextField type='number' name='tenant_phone_number' value={tenantDetails.tenant_phone_number} fullWidth size='small' label='Phone Number' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
        </Grid>
        <Grid item xs={6}>
          <TextField type='text' name='pin' value={tenantDetails.pin} fullWidth size='small' label='Business pin' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
        </Grid>
        <Grid item xs={6}>
          <TextField type='text' name='address' value={tenantDetails.address} fullWidth size='small' label='Address' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
        </Grid>
        <Grid item xs={12}>
          <Button type='submit' onClick={handleCreatetenant}>Submit</Button>
        </Grid>

      </Grid>
    </FormGroup>
  )
}

export default CreateMerchant