import React, { useState } from 'react';
import { Grid, TextField, Button,  FormControl, FormGroup, InputLabel, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createMerchantService } from '../redux/slices/sliceActions';
import SnackBar from './snackBar';



const CreateMerchantService = ({  setOpen }) => {
  const dispatch = useDispatch()
  const {  user, loading, error} = useSelector(state => state.usersslice);
  const services = useSelector(state => state?.usersslice.services?.data);
  const merchantaccounts = useSelector(state => state?.usersslice.merchantaccounts?.data);
  const token = user?.access_token;

  const {user_id: id} = useParams();

  const [serviceDetails, setservice] = useState({
    service_name: "",
    service: "",
    float_account: "",
    
  })

  const handleValueChange = (e) => {
    setservice({ ...serviceDetails, [e.target.name]: e.target.value })
  }
  const handleCreateservice = () => {
    const merchantServices = {tenant_service_name: serviceDetails.service_name, service: serviceDetails.service, float_account: serviceDetails.float_account }
    dispatch(createMerchantService({token,id, merchantServices}))
    setOpen(false);

  }

  return (
    <FormGroup >
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField type='text' name='service_name' value={serviceDetails.service_name} fullWidth size='small' label='Service Name' required InputLabelProps={{ shrink: true }} onChange={handleValueChange} />
      </Grid>
      <Grid item xs={6}>
      <FormControl fullWidth>
            <InputLabel id="service_label">Service</InputLabel>
            <Select
              required
              fullWidth
              labelId="service_label"
              id="service-select"
              value={serviceDetails.service}
              label="Float"
              name="service"
              onChange={handleValueChange} size="small"
            >

              {services ? services.map(ser => (<MenuItem key={ser.service_id} value={ser.service_id}>{ser.service_name}</MenuItem>)) : <MenuItem value={0}>no services</MenuItem>}
            </Select>
          </FormControl>

      </Grid>
      <Grid item xs={6}>
      <FormControl fullWidth>
            <InputLabel id="account_label">Float Account</InputLabel>
            <Select
              required
              fullWidth
              labelId="account_label"
              id="account-select"
              value={serviceDetails.float_account}
              label="Float"
              name="float_account"
              onChange={handleValueChange} size="small"
            >

              {merchantaccounts ? merchantaccounts.map(maccounts => (<MenuItem key={maccounts.tenant_float_account_id} value={maccounts.tenant_float_account_id}>{maccounts.tenant_float_account_name}</MenuItem>)) : <MenuItem value={0}>no accounts</MenuItem>}
            </Select>
          </FormControl>

      </Grid>
      
      <Grid item xs={12}>
        <Button type='submit' onClick={handleCreateservice}>Submit</Button>
      </Grid>

    </Grid>
  </FormGroup>
  )
}

export default CreateMerchantService